import React, { useEffect } from 'react';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import { authProtectedRoutes, publicRoutes } from 'routes';
import AppRoute from 'routes/router';
import { baselightTheme } from 'theme/DefaultColors';
import Modal from 'react-modal';
import { ToastComponent } from 'components/ToastComponent';
import 'react-day-picker/dist/style.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-loading-skeleton/dist/skeleton.css';
import 'react-international-phone/style.css';

function App() {
  const theme = baselightTheme;

  useEffect(() => {
    Modal.setAppElement('#root');
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <div className={[`font-plusjakarta h-full`]}>
        <CssBaseline />
        <Router>
          <Routes>
            {publicRoutes.map((item, key) => (
              <Route key={key} element={item.element} path={item.path} exact={item.exact} />
            ))}
            <Route element={<AppRoute />}>
              {authProtectedRoutes.map((item, key) => (
                <Route key={key} element={item.element} path={item.path} exact={item.exact} />
              ))}
            </Route>
          </Routes>
        </Router>
        <ToastComponent />
      </div>
    </ThemeProvider>
  );
}

export default App;
